import React, { useState } from "react";
import Countdown from "react-countdown";
import "./App.css";


const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <span className="completed">Chegou o grande dia!</span>;
  } else {
    return (
      <div className="countdown-container">
        <div>
          <div className="countdown-box">
            <span className="time">{days}</span>
          </div>
          <span className="label">DIAS</span>
        </div>

        <div>
          <div className="countdown-box">
            <span className="time">{hours}</span>
          </div>
          <span className="label">HORAS</span>
        </div>
        <div>
          <div className="countdown-box">
            <span className="time">{minutes}</span>
          </div>
          <span className="label">MINUTOS</span>
        </div>
        <div>
          <div className="countdown-box">
            <span className="time">{seconds}</span>
          </div>
          <span className="label">SEGUNDOS</span>
        </div>
      </div>
    );
  }
};

function App() {
  const targetDate = new Date("2024-10-18T00:00:00");
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="App">
      <header className="App-header">
        <p
          style={{
            fontSize: "3em",
            marginBottom: "20px",
            fontFamily: "monospace",
            fontWeight: "bold",
          }}
        >
          Dias para o Tomelo Day
        </p>
        <Countdown date={targetDate} renderer={renderer} />
        <img
          src="/aviso.png"
          alt="Tomelo Day"
          style={{ width: "300px", marginTop: "20px" }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            textAlign: "center",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img
            src="/cabeca.png"
            alt="Tomelo"
            style={{ width: "80px", cursor: "pointer" }}
          />
          {isHovered && (
            <div>
            <div
              style={{
                position: "absolute",
                bottom: "100%", 
                right: "0",
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
                whiteSpace: "nowrap",
                zIndex: 1, 
              }}
            >
              Preparado para o Tomelo Day?
            </div>
            <p
            style={{
              position: "absolute",
              bottom: "120%", 
              right: "0",
              backgroundColor: "rgba(0, 0, 0, 0.75)",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
              whiteSpace: "nowrap",
              zIndex: 1, 
              fontSize: "0.6em",
            }}
            >
              que beleza cabeça amigo
            </p>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
